import React, { useState, useEffect } from 'react';
import axios from "axios";

// icons
import fbLogo from "../../components/assets/img/header/menu/ic-fb-white.png";
import inLogo from "../../components/assets/img/header/menu/ic-in-white.png";
import wpLogo from "../../components/assets/img/header/menu/ic-wp-white.png";

const SideBar = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState({});

  useEffect(() => {
    const handleGetAllData = () => {
      axios
        .get("content-group/9")
        .then(res => {
          let data = res.data || [];
          setData(data);
        })
        .catch(err => {
          console.log(err);
        })
    }

    handleGetAllData();
  }, [])

  useEffect(() => {
    if(data.Contents) {
      let phoneList = data.Contents.filter(item => item.Key.includes("geral-contato-telefone")).map(item => item.Value);
      let emailList = data.Contents.filter(item => item.Key.includes("geral-contato-email")).map(item => item.Value);
      let endereco = data.Contents.find(item => item.Key === "geral-contato-endereco").Value;
      let facebook = data.Contents.find(item => item.Key === "geral-contato-facebook").Value;
      let linkedin = data.Contents.find(item => item.Key === "geral-contato-linkedin").Value;
      let whatsapp = data.Contents.find(item => item.Key === "geral-contato-whatsapp").Value;

      setAllData(allData => ({
          ...allData,
          phoneList,
          emailList,
          endereco,
          facebook,
          linkedin,
          whatsapp,
      }))
  }
  }, [data])

  return (
    <div className="wrapper-right">
      <div className="item">
        <h2>Onde estamos</h2>
        <p>{allData.endereco}</p>
      </div>
      <div className="item">
        <h2>Contato</h2>
        <ul>
          {allData.phoneList ? allData.phoneList.map((item, index) => (
            <li key={index}>{item}</li>            
          )) : null}
          </ul>
      </div>
      <div className="item">
        <h2>Nossos e-mails</h2>
        <ul>
          {allData.emailList ? allData.emailList.map((item, index) => (
            <li key={index}>{item}</li>
          )) : null}
        </ul>
      </div>
      <div className="button-group">
        <a href={allData.facebook} target="_blank" rel="noreferrer" ><div><img className="icon" alt="Página Facebook" src={fbLogo} /></div></a>
        <a href={allData.linkedin} target="_blank" rel="noreferrer" ><div><img className="icon" alt="Perfil LinkedIn" src={inLogo} /></div></a>
        <a href={allData.whatsapp} target="_blank" rel="noreferrer" ><div><img className="icon" alt="Grupo Whatsapp" src={wpLogo} /></div></a>
      </div>
    </div>
  )
}

export default SideBar;

